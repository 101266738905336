const config = {
  gatsby: {
    pathPrefix: '/',
    siteUrl: 'https://docs.concourse.farm',
    gaTrackingId: 'G-5YLB652B3S',
    trailingSlash: false,
  },
  header: {
    logo: '',
    logoLink: 'https://docs.concourse.farm/',
    title:
      "<a href='/'>concourse.farm</a>",
    githubUrl: 'https://github.com/trullla/concourse-docs',
    helpUrl: '',
    tweetText: '',
    social: ``,
    links: [{ text: '', link: '' }],
    search: {
      enabled: process.env.GATSBY_ALGOLIA_ENABLED,
      indexName: process.env.GATSBY_ALGOLIA_INDEX_NAME,
      algoliaAppId: process.env.GATSBY_ALGOLIA_APP_ID,
      algoliaSearchKey: process.env.GATSBY_ALGOLIA_SEARCH_KEY,
      algoliaAdminKey: process.env.GATSBY_ALGOLIA_ADMIN_KEY,
    },
  },
  sidebar: {
    forcedNavOrder: [
      '/introduction',
      '/power-tutorial',
      '/integrations',
      '/cookbook',
      '/config',
      '/workarounds',
    ],
    collapsedNav: [
    ],
    links: [
      { text: 'concourse-ci.org', link: 'https://concourse-ci.org' },
      { text: 'concourse.farm', link: 'https://concourse.farm' },
      { text: 'TRULLLA Software', link: 'https://trullla.com' },
    ],
    frontline: false,
    ignoreIndex: true,
    title:
    "<a href='/'>docs</a><div class='greenCircle'></div>",
  },
  siteMetadata: {
    title: 'docs.concourse.farm',
    description: 'Concourse CI docs',
    ogImage: null,
    docsLocation: 'https://github.com/trullla/concourse-docs/tree/main/content',
    favicon: '',
  },
  pwa: {
    enabled: false, // disabling this will also remove the existing service worker.
    manifest: {
      name: 'Concourse.Farm - CI system documentation',
      short_name: 'Concourse.Farm',
      start_url: '/',
      background_color: '#6b37bf',
      theme_color: '#6b37bf',
      display: 'standalone',
      crossOrigin: 'use-credentials',
      icons: [
        {
          src: 'src/pwa-512.svg',
          sizes: `512x512`,
          type: `image/svg`,
        },
      ],
    },
  },
};

module.exports = config;
